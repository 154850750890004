import { groq } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";

import Page from "@/components/pages/page/Page";
import LiveQueryWrapper from "@/components/preview/LiveQueryWrapper";
import { readToken } from "@/lib/sanity.api";
import { getClient } from "@/lib/sanity.client";
import { blocks, refMedia } from "@/lib/sanity.queries";

export default function Route(props) {
  const { query, params, draftMode } = props;

  const [data, loading] = useLiveQuery(props?.data, query, params);

  return <Page {...(!data?._id ? data : { page: data })} />;
}

export const getServerSideProps = async (ctx) => {
  const { draftMode = false, params = {}, locale } = ctx;
  const client = getClient(draftMode ? readToken : undefined);
  const query = groq`*[_type == "home" && language in [$locale]][0]{
    ...,
    content[] {
      ...,
      markDefs[]{
        ...,
        reference->,
      },
      ${blocks},
      gallery[]{..., ${refMedia}}, media{...,${refMedia}},
    },
    "_translations": *[_type == "translation.metadata" && references(^._id)].translations[].value->{
      _type, title, slug, language
    }}`;

  const [page] = await Promise.all([
    client.fetch(query, { ...params, locale }),
  ]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      data: { page },
      draftMode,
      query,
      params: { ...params, locale },
      token: draftMode ? readToken : "",
    },
  };
};
